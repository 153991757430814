export const getPickupPoints = async () => {
  const API_URL = process.env.REACT_APP_API_URL;

  const myHeaders = new Headers();

  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("x-api-key", process.env.REACT_APP_APIKEY_AGENCIES_V3);
  myHeaders.append("Channel", "Mapa pickup");

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const response = await fetch(API_URL, requestOptions);
    return await response.json();
  } catch (error) {
    console.error("no response", error);
  }
};
